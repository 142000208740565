/*jslint white: true, nomen: true, unparam : true, es5: true */
/*global document, window, Cookies, jQuery */
(function( $ ) {
    "use strict";
    
    $( document ).ready(function() {	
	if (window.innerWidth < 768){
	    $('.bouton-contact-menu').appendTo('nav.elementor-nav-menu__container');

	}

	$('#searchandfilter').on('change', function(){
	    $(this).submit();
	});
	
	$('#searchandfilter').on('submit', function(event) {
            event.preventDefault(); // Prevent the default form submission
	    var urlajax = $(this).data('url');
	    var formData = new FormData(this);
	    formData.append('action', 'get_latest_articles');

            $.ajax({
		url: urlajax,
		method: 'POST',
		data: formData,
		processData: false, 
		contentType: false, 
		success: function(response) {
                    $('#result').html(response);
		},
		error: function(xhr, status, error) {
                    console.error(xhr.responseText);
		}
            });
	});

	if ($('#searchandfilter')){
	    $('#searchandfilter').submit();
	}

	$('#searchandfilter-formations').on('change', function(){
	    $(this).submit();
	});
	
	$('#searchandfilter-formations').on('submit', function(event) {
	    // Compter le nombre de cases cochées pour les publics
	    var checkedPublics = $(this).find('input[name="publics[]"]:checked').length;
	    // Mettre à jour le texte dans total-public
	    if (checkedPublics > 0){
		$('.total-public').text(checkedPublics);
		$('.total-public').addClass('show');
	    }
	    else{
		$('.total-public').removeClass('show');
	    }

	    // Compter le nombre de cases cochées pour les finalites
	    var checkedFinalites = $(this).find('input[name="finalites[]"]:checked').length;
	    // Mettre à jour le texte dans total-finalite
	    if (checkedFinalites > 0){
		$('.total-finalite').text(checkedFinalites);
		$('.total-finalite').addClass('show');
	    }
	    else{
		$('.total-finalite').removeClass('show');
	    }

	    // Compter le nombre de cases cochées pour les thematiques
	    var checkedThematiques = $(this).find('input[name="thematiques[]"]:checked').length;
	    // Mettre à jour le texte dans total-thematique
	    if (checkedThematiques > 0){
		$('.total-thematique').text(checkedThematiques);
		$('.total-thematique').addClass('show');
	    }
	    else{
		$('.total-thematique').removeClass('show');
	    }

            event.preventDefault(); // Prevent the default form submission
	    var urlajax = $(this).data('url');
	    var formData = new FormData(this);
	    formData.append('action', 'get_formations');
	    
            $.ajax({
		url: urlajax,
		method: 'POST',
		data: formData,
		processData: false, 
		contentType: false, 
		success: function(response) {        
                    $('#result').html(response);
		},
		error: function(xhr, status, error) {
                    console.error(xhr.responseText);
		}
            });
	});

	if ($('#searchandfilter-formations')){
	     $('#searchandfilter-formations').submit();
	}

	$('.search-filters .titre-search').click(function(){
	    var checkboxes = $(this).siblings('.checkboxes');
	    if (checkboxes.hasClass('selected')) {
		checkboxes.removeClass('selected').hide(); // Retirer la classe et cacher
	    } else {
		$('.checkboxes').removeClass('selected').hide(); // Cacher toutes les autres
		checkboxes.addClass('selected').show(); // Montrer celle sélectionnée
	    }
	});
	$('#result').click(function(){
	    $('.checkboxes').hide(); 
	});

	
	// formation
	$('.single-formation .lst-programme > div').click(function(){
	    $(this).toggleClass('selected');
	});

    });

}( jQuery ) );
